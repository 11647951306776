import React from 'react';
import CustomLink from '../CustomLink';

import styles from './footer.module.scss';
import '../../styles/styles.scss'; // global styles

// eslint-disable-next-line import/prefer-default-export
export const Footer = () => {
	return (
		<footer className={[styles.footer, 'font30', 'animation-fadein'].join(' ')}>
			<small>
				<div>© Copyright 2020, Rockoder Ltd. All rights reserved.</div>

				<CustomLink
					linkType="external"
					linkURL="mailto:joao@rockoder.co.uk"
					className="navbar-menuLink">
					Contact
				</CustomLink>
			</small>
		</footer>
	);
};
