import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';

import './post-page.scss';

export const MarkdowPageTemplate = ({ body }) => {
	return (
		<>
			<div className="layouts--section">
				<div className="layouts--container__slim">
					<div className="blog-post" dangerouslySetInnerHTML={{ __html: body }} />
				</div>
			</div>
		</>
	);
};

class MarkdownPage extends React.Component {
	render() {
		const { data } = this.props;
		const body = data.page.edges[0].node.html;
		const {
			title: seoTitle,
			description: seoDescription,
			browserTitle
		} = data.page.edges[0].node.frontmatter.seo;

		return (
			<Layout>
				<Helmet>
					<meta name="title" content={seoTitle} />
					<meta name="description" content={seoDescription} />
					<title>{browserTitle}</title>
				</Helmet>
				<MarkdowPageTemplate body={body} />
			</Layout>
		);
	}
}

MarkdownPage.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array
		})
	})
};

export default MarkdownPage;

export const pageQuery = graphql`
	query($id: String!) {
		page: allMarkdownRemark(filter: { id: { eq: $id } }) {
			edges {
				node {
					html
					frontmatter {
						seo {
							browserTitle
							title
							description
						}
					}
				}
			}
		}
	}
`;
