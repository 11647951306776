import React from 'react';

// import "./styles.scss";
import CustomLink from '../CustomLink';

// eslint-disable-next-line import/prefer-default-export
export const Navbar = () => (
	<header>
		<CustomLink linkType="internal" linkUrl="/">
			Rockoder
		</CustomLink>
	</header>
);
