import React from 'react';

import { Navbar } from './Navbar';
import { Footer } from './Footer';

const Layout = ({ navbarData = null, fixedHeader = false, children }) => (
	<div>
		<Navbar data={navbarData} fixed={fixedHeader} />
		<main>{children}</main>
		<Footer />
	</div>
);

export default Layout;
