import React from 'react';
import { Link } from 'gatsby';

class CustomLink extends React.Component {
	state = {};

	componentDidCatch() {
		// Display fallback UI
		this.setState({ hasError: true });
	}

	render() {
		const { linkType, linkURL, children, className = '' } = this.props;
		if (linkType === 'internal' && !this.state.hasError) {
			return (
				<Link className={className} to={linkURL}>
					{children}
				</Link>
			);
		}
		return (
			<a className={className} href={linkURL}>
				{children}
			</a>
		);
	}
}

export default CustomLink;
